import React from "react";
import { motion } from "framer-motion";

function Home() {
    return (
        <div className="home">
            <motion.div className="gdg"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ type: 'spring', delay: 0.2 }}
            >
                <img src="/images/gdg-white.svg" alt='Logo'/>
            </motion.div>
            <motion.div className="comingsoon"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ type: 'spring', delay: 0.4 }}
            >
                Coming soon
            </motion.div>
        </div>
    );
}

export default Home;
